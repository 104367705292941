import Vue from "vue";
import Router from "vue-router";

import Home from "../layout/Home.vue";//显示打开菜单的标签
import Login from "../views/login";//登录
import home from "../views/home.vue";//显示首页
//基础数据
import drug from '../views/drug/drug.vue';//药品管理
import drugType from '../views/drug/drugType.vue';//药品类别
import pharmacy from '../views/drug/pharmacy.vue';//药房管理
import diagResult from '../views/drug/diagResult.vue';//中医症型管理
import recipe from '../views/drug/recipe.vue';//处方管理
import tjzx from '../views/drug/tjzx.vue';//调剂中心

import hospital from '../views/hospital/hospital.vue';//医院管理
import patient from '../views/hospital/patient.vue';//患者信息
import dept from '../views/hospital/dept.vue';//科室管理
import doctor from '../views/hospital/doctor.vue';//医生管理
import doctorSee from '../views/hospital/doctorSee.vue';//应诊记录 
import itemCost from '../views/hospital/itemCost.vue';//服务项目
import doctorRatio from '../views/hospital/doctorRatio.vue';//医生个性化
import order from '../views/order/order.vue';//订单管理
import sendOrder from '../views/order/sendOrder.vue';//订单分发页面

//财务分析
import show from '../views/finance/financeOrder.vue';//服务项目
import stati from '../views/finance/stati.vue';//服务项目
import orderStat from '../views/finance/orderStat.vue';//订单统计
// ==============订单统计（子路由）==============
import aystj from '../views/finance/aystj.vue';//按医生统计
import ayftj from '../views/finance/ayftj.vue';//按药房统计
import ajxtj from '../views/finance/ajxtj.vue';//按剂型统计
import aywtj from '../views/finance/aywtj.vue';//按业务员统计
import yszjtj from '../views/finance/yszjtj.vue';//医生诊金统计

// ============================================

//系统维护
import user from "../views/sys/user.vue";//人员管理
import role from '../views/sys/role.vue';//角色管理
import log from '../views/sys/log.vue';//操作日志

//仲景学苑
import column from "../views/zj/column.vue"//栏目管理
import content from "../views/zj/content.vue"//内容管理

//营销管理

import cash from "../views/market/cash.vue"//栏目管理
import salesperson from "../views/market/salesperson.vue"//内容管理
import charge from "../views/market/charge.vue"//佣金核销
import doctorPoint from "../views/market/doctorPoint.vue"//医生积分
import salesPoint from "../views/market/salesPoint.vue"//业务员积分
//商城管理

import info from "../views/product/info.vue"//商品管理
import productOrder from "../views/product/productOrder.vue"//订单管理
import exchange from "../views/product/exchange.vue"//兑换统计
import advice from "../views/drug/advice.vue"


Vue.use(Router);

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: '登录',
    component: Login,
  },
  {
    path: "/",
    name: "home",
    component: Home,
    children: [
      {
        path: "/home",
        name: "home",
        component: home,
        meta: {
          title: "首页"
        }
      },
      {
        path: "/patient",
        name: "patient",
        component: patient,
        meta: {
          title: "患者信息",
        }
      },
      {
        path: "/hospital",
        name: "hospital",
        component: hospital,
        meta: {
          title: "医院信息",
        }
      },
      {
        path: "/dept",
        name: "dept",
        component: dept,
        meta: {
          title: "科室信息",
        }
      },
      {
        path: "/doctor",
        name: "doctor",
        component: doctor,
        meta: {
          title: "医生信息",
        }
      },
      {
        path: "/doctorRatio",
        name: "doctorRatio",
        component: doctorRatio,
        meta: {
          title: "医生提现设置",
        }
      },

      {
        path: "/doctorSee",
        name: "doctorSee",
        component: doctorSee,
        meta: {
          title: "应诊信息",
        }
      },
      {
        path: "/itemCost",
        name: "itemCost",
        component: itemCost,
        meta: {
          title: "服务项目",
        }
      },
      {
        path: "/drug",
        name: "drug",
        component: drug,
        meta: {
          title: "药品信息",
        }
      },
      {
        path: "/drugType",
        name: "drugType",
        component: drugType,
        meta: {
          title: "药品类别",
        }
      },
      {
        path: "/pharmacy",
        name: "pharmacy",
        component: pharmacy,
        meta: {
          title: "药房管理",
        }
      },
      {
        path: "/diagResult",
        name: "diagResult",
        component: diagResult,
        meta: {
          title: "中医症型",
        }
      },
      {
        path: "/recipe",
        name: "recipe",
        component: recipe,
        meta: {
          title: "处方管理",
        }
      },
      {
        path: "/order",
        name: "order",
        component: order,
        meta: {
          title: "所有订单",
        }
      },
      {
        path: "/wait",
        name: "wait",
        component: order,
        meta: {
          title: "待发货订单",
        }
      },
      {
        path: "/send",
        name: "send",
        component: order,
        meta: {
          title: "已发货订单",
        }
      },
      {
        path: "/sign",
        name: "sign",
        component: order,
        meta: {
          title: "已签收订单",
        }
      },
      {
        path: "/sendOrder",
        name: "sendOrder",
        component: sendOrder,
        meta: {
          title: "订单分发",
        }
      },

      {
        path: "/user",
        name: "user",
        component: user,
        meta: {
          title: "用户管理"
        }
      },
      {
        path: "/role",
        name: "role",
        component: role,
        meta: {
          title: "角色管理"
        }
      },
      {
        path: "/log",
        name: "log",
        component: log,
        meta: {
          title: "操作日志"
        }
      },
      //财务分析
      {
        path: "/show",
        name: "show",
        component: show,
        meta: {
          title: "财务订单"
        }
      },
      {
        path: "/stati",
        name: "stati",
        component: stati,
        meta: {
          title: "交易统计"
        }
      },
      {
        path: "/cash",
        name: "cash",
        component: cash,
        meta: {
          title: "提现设置"
        }
      },
      {
        path: "/sales",
        name: "sales",
        component: salesperson,
        meta: {
          title: "业务人员资料"
        }
      },
      {
        path: "/charge",
        name: "charge",
        component: charge,
        meta: {
          title: "佣金核销"
        }
      },
      {
        path: "/doctorPoint",
        name: "doctorPoint",
        component: doctorPoint,
        meta: {
          title: "医生积分"
        }
      },
      {
        path: "/salesPoint",
        name: "salesPoint",
        component: salesPoint,
        meta: {
          title: "业务员积分"
        }
      },

      //仲景学苑
      {
        path: "/column",
        name: "column",
        component: column,
        meta: {
          title: "栏目管理"
        }
      },
      {
        path: "/content",
        name: "content",
        component: content,
        meta: {
          title: "内容管理"
        }
      },
      //商城管理
      {
        path: "/info",
        name: "info",
        component: info,
        meta: {
          title: "商品管理"
        }
      },
      {
        path: "/productOrder",
        name: "productOrder",
        component: productOrder,
        meta: {
          title: "订单管理"
        }
      },
      {
        path: "/exchange",
        name: "exchange",
        component: exchange,
        meta: {
          title: "兑换统计"
        }
      },
      {
        path: "/advice",
        name: "advice",
        component: advice,
        meta: {
          title: "医嘱模版"
        }
      },
      {
        path: "/orderStat",
        name: "orderStat",
        component: orderStat,
        meta: {
          title: "订单统计"
        }
      },
      {
        path: "/tjzx",
        name: "tjzx",
        component: tjzx,
        meta: {
          title: "调剂中心"
        }
      }


    ]
  },
  {
    path: "/ayftj",
    name: "ayftj",
    component: ayftj,

  },
  {
    path: "/ajxtj",
    name: "ajxtj",
    component: ajxtj,

  },
  {
    path: "/aywtj",
    name: "aywtj",
    component: aywtj,

  },{
    path: "/aystj",
    name: "aystj",
    component: aystj,
  },{
    path: "/yszjtj",
    name: "yszjtj",
    component: yszjtj,
  }
];

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes
});

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router;
