<template>
  <div class="menobox">
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-row>
        <el-form-item label="统计时间">
          <el-date-picker v-model="dateRange" end-placeholder="结束日期" size="mini" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="医生姓名">
          <el-input v-model="searchForm.doctor_name" placeholder="请输入医生姓名" clearable size="mini"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="一级医助">
          <el-input v-model="searchForm.sales_name1" placeholder="请输入一级医助" clearable size="mini"></el-input>
        </el-form-item>
        <el-form-item label="二级医助">
          <el-input v-model="searchForm.sales_name2" placeholder="请输入二级医助" clearable size="mini"></el-input>
        </el-form-item>
        <el-form-item label="三级医助">
          <el-input v-model="searchForm.sales_name3" placeholder="请输入三级医助" clearable size="mini"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="handleStatistics">统计</el-button>
          <el-button size="mini" type="success" @click="handleExport">导出</el-button>
        </el-form-item>
      </el-row>


    </el-form>

    <el-table v-loading="loading" :data="tableData" :summary-method="getSummaries" border max-height="680" show-summary size="mini" style="width:1221px">
      <el-table-column label="排名" type="index" width="60"></el-table-column>
      <el-table-column label="医生姓名" prop="doctor_name" width="130"></el-table-column>
      <el-table-column label="医生电话" prop="doctor_phone" width="100"></el-table-column>
      <el-table-column label="一级医助" prop="sales_name1" width="130"></el-table-column>
      <el-table-column label="一级医助电话" prop="sales_phone1" width="100"></el-table-column>
      <el-table-column label="二级医助" prop="sales_name2" width="130"></el-table-column>
      <el-table-column label="二级医助电话" prop="sales_phone2" width="100"></el-table-column>
      <el-table-column label="三级医助" prop="sales_name3" width="130"></el-table-column>
      <el-table-column label="三级医助电话" prop="sales_phone3" width="100"></el-table-column>
      <el-table-column label="订单数量" prop="order_num" width="100"></el-table-column>
      <el-table-column align="right" header-align="center" label="药品订单额（元）" prop="drug_amount" width="140"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import BaseUrl from '@/utils/baseUrl';

export default {
  data() {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return {
      searchForm: {
        start_time: formattedDate,
        end_time: formattedDate,
        doctor_name: '',
        sales_name1: '',
        sales_name2: '',
        sales_name3: '',
      },
      dateRange: [formattedDate, formattedDate],
      tableData: [],
      loading: false,
    };
  },
  watch: {
    dateRange(newValue) {
      if (newValue == null) {
        this.searchForm.start_time = ''
        this.searchForm.end_time = ''
        return
      }
      this.searchForm.start_time = newValue[0];
      this.searchForm.end_time = newValue[1];
    }
  },
  created() {
    this.handleStatistics();
  },
  methods: {
    handleStatistics() {
      this.loading = true;
      this.$http.post(BaseUrl.getOrderStatDoctorList, this.searchForm).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.loading = false;
        this.$message({
          message: error,
          type: 'warning'
        });
      });
    },

    handleExport() {
      let obj = this.searchForm;
      this.$http.postFile(BaseUrl.getOrderStatDoctorListExcel, obj, {
        responseType: 'arraybuffer'
      }).then(res => {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var objectUrl = URL.createObjectURL(blob);
        downloadElement.href = objectUrl;
        downloadElement.download = '按医生统计.xls'; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
      }).catch(
        function (res) {
        }
      )
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        let nullList =[1,2,3,4,5,6,7,8]
        if (nullList.includes(index)) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          const sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sum.toFixed(2); // 解决精度问题
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
  },
};
</script>

<style scoped>
.menobox {
  padding: 5px;
}
</style>