<!--按业务员统计-->
<template>
  <div class="menobox">
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="统计时间">
        <el-date-picker v-model="dateRange" size="mini" type="daterange" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="handleStatistics">统计</el-button>
        <el-button type="success" size="mini" @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table :data="tableData" size="mini" border v-loading="loading" style="width: 781px" max-height="680px" :summary-method="getSummaries" show-summary>
      <el-table-column type="index" label="排名" width="60"></el-table-column>
      <el-table-column prop="sales_name" label="业务员姓名" width="180"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="180"></el-table-column>
      <el-table-column prop="order_num" label="订单数量" width="180"></el-table-column>
      <el-table-column prop="drug_amount" label="药品订单额（元）" align="right" header-align="center" width="180"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import BaseUrl from "utils/baseUrl";

export default {
  data() {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    return {
      searchForm: {
        start_time: formattedDate,
        end_time: formattedDate
      },
      dateRange: [formattedDate, formattedDate],
      tableData: [],
      loading: false,
    };
  },
  watch: {
    dateRange(newValue, oldValue) {
      if (newValue == null) {
        this.searchForm.start_time = ''
        this.searchForm.end_time = ''
        return
      }
      this.searchForm.start_time = newValue[0]
      this.searchForm.end_time = newValue[1]
    }

  },
  created() {
    this.handleStatistics()
  },
  methods: {
    handleStatistics() {
      this.loading = true;
      this.$http.post(BaseUrl.etOrderStatSalesList, this.searchForm).then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          this.tableData = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          });
        }
      }).catch(error => {
        this.loading = false;
        this.$message({
          message: error,
          type: 'warning'
        });
      });
    },
    handleExport(){
      let obj = this.searchForm;
      this.$http.postFile(BaseUrl.getOrderStatSalesListExcel, obj, {
        responseType: 'arraybuffer'
      }).then(res => {
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        });
        var downloadElement = document.createElement('a');
        var objectUrl = URL.createObjectURL(blob);
        downloadElement.href = objectUrl;
        downloadElement.download = '按业务员统计.xls'; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
      }).catch(
        function(res) {}
      )
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0 ) {
          sums[index] = '合计';
          return;
        }
        if (index === 1|| index === 2) {
          sums[index] = '';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          const sum = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sum.toFixed(2); // 解决精度问题
        } else {
          sums[index] = '';
        }
      });

      return sums;
    },
  },
};
</script>

<style scoped>
.menobox {
  padding: 5px;
}
</style>